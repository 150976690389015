import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const LayoutContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 100vw;
  padding: 12px;

  @media (min-width: 480px) {
    max-width: 80vw;
    padding: 24px 12px;
  }
`

const SecondaryHeader = styled.h3`
  margin-top: 0;
  font-family: "Montserrat";
  font-weight: 900;
  background-color: yellow;
  width: 90px;
`

const Footer = styled.footer`
  font-size: 12px;
  font-family: "Montserrat";
  font-weight: 300;
`

const Social = styled.section`
  float: right;

  a {
    box-shadow: none;
  }

  img {
    margin-right: 12px;
    cursor: pointer;
    width: 25px;

    :hover {
      vertical-align: top;
      -webkit-transform: scale(1.15) rotate(5deg);
      transform: scale(1.15) rotate(5deg);
    }

    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
`

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header = null

  if (location.pathname !== rootPath) {
    header = (
      <SecondaryHeader>
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </SecondaryHeader>
    )
  }

  return (
    <LayoutContainer>
      <header>
        {header}
        <Social>
          <a target="_blank" rel="noreferrer" href="https://github.com/madtab7">
            <img src="/github.png" alt="Github" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/madeleine-tabing/"
          >
            <img src="/linkedin.png" alt="LinkedIn" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/madtab">
            <img src="/twitter.png" alt="Twitter" />
          </a>
          <a target="_blank" rel="noreferrer" href="mailto:madtab7@gmail.com">
            <img src="/mail.png" alt="Email" />
          </a>
        </Social>
      </header>
      <main>{children}</main>
      <Footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </Footer>
    </LayoutContainer>
  )
}

export default Layout
